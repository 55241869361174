import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getDiscountGroups: createCancelTokenHandler('getDiscountGroups')
}


export function getDiscountGroups (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/club/discount-groups${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getDiscountGroups'].handleRequestCancellation().token
  })
}

export function getActiveDiscountGroup (id) {
  return axios({
    url: `v1/admin/club/discount-groups/${id}`,
    method: 'get'
  })
}

export function editDiscountGroups (payment) {
  return axios.patch('v1/admin/club/discount-groups', payment)
}
