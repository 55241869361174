<template>
  <div class="sales-discount-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table v-if="columnsLabel.length > 1"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="handleRowClicked"
                             @load:more="getDiscountGroups"/>

    <!-- edit user prompt -->
    <vs-prompt
      class="big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editSaleAddonPromptStatus"
      @close="editSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('sendDiscountDataBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.discount.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-sale-discount :in-modal="true" @edited="editSaleAddonPromptStatus = false, getDiscountGroups()"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="editSaleDiscount" v-show="false" @click="editSaleAddonPromptStatus = true"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import {checkUserPermissions, hideLoading, showLoading} from "../../../../../assets/js/functions";
  import {getTerms} from "../../../../../http/requests/club/terms";
  import EditSaleDiscount from "../edit/editSaleDiscount";
  import {getDiscountGroups} from "../../../../../http/requests/club/discountGroups";
  export default {
    name: "saleDiscountList",
    components: {EditSaleDiscount, CustomIcon},
    metaInfo () {
      return {
        title: this.$t('sales.discount.title')
      }
    },
    data () {
      return {
        showSaleAddonPromptStatus: false,
        insertNewSaleAddonPromptStatus: false,
        editSaleAddonPromptStatus: false,
        selectedRow: {},
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'name',
            i18n: 'sales.discount.table.header.name',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 120,
          },
        ],
        actions: [
          {
            toolbar: [
              {
                id: 'editSaleDiscount',
                icon: 'EDIT',
                iconPack: 'useral',
                color: 'warning',
                permission: 'discount_group.update'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created () {
      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getDiscountGroups()
      this.getTerms()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getDiscountGroups () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleDiscountsList && this.data.length === 0) this.$refs.saleDiscountsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleDiscountsList && this.data.length > 0) this.$refs.saleDiscountsList.loadMoreStatus = 'Loading'

            getDiscountGroups(this.page, this.filters, this.sorts).then((response) => {
              const discounts = response.data
              let data = []
              discounts.data.forEach((saleAddon) => {

                if (!data[`character_${saleAddon.character.id}`]) {
                  data[`character_${saleAddon.character.id}`] = []
                }
                data[`character_${saleAddon.character.id}`].id = saleAddon.character.id
                data[`character_${saleAddon.character.id}`].name = saleAddon.character.name
                if (saleAddon.percent > 0) {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = {
                    value: saleAddon.percent,
                    type: 'price',
                    currency: '%'
                  }
                } else {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = {
                    value: saleAddon.price,
                    type: 'price',
                    currency: this.$locale.currency()
                  }
                }
              })
              this.data = Object.values(data)

              this.total_count = saleAddons.pagination.total
              this.page = saleAddons.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.saleDiscountsList) this.$refs.saleDiscountsList.loadMoreStatus = ''
            }).catch((error) => {
              if (this.$refs.saleDiscountsList && !axios.isCancel(error)) this.$refs.saleDiscountsList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      getTerms () {
        getTerms().then(response => {
          const terms = response.data.data
          let columnsLabel = JSON.parse(JSON.stringify(this.columnsLabel))
          terms.forEach(term => {
            columnsLabel.unshift(
              {
                field: `term_${term.id}`,
                headerName: term.name,
                width: 'calc(100% / 10)',
                minWidth: 100,
              })
          })
          this.columnsLabel = []
          this.columnsLabel = columnsLabel
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      },
      handleRowClicked (row) {
        this.selectedRow = row
        this.showSaleAddonPromptStatus = true
      },
      handleReloadList () {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.showSaleAddonPromptStatus = false
        this.insertNewSaleAddonPromptStatus = false
        this.editSaleAddonPromptStatus = false
        this.getDiscountGroups()
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
